import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { Field, Form, withFormik } from "formik";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Cancel from "@material-ui/icons/Cancel";
import ButtonIcon from "components/styled/ButtonIcon";
import { useTranslation } from "react-i18next";
import { TextField, Chip,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { getOperationsFromColumns } from "../../utils/analytics/dataManipulation";
import history from '../../utils/history';
import useGlobal from "../../store";

const checklistAnalisysFormSchema = yup.object().shape({
  app: yup.string().required(),
  visit: yup.string().required(),
  keyField: yup.string().required(),
  name: yup.string().required(),
  groups: yup.array(),
  emails: yup.array().required(),
  operation: yup.string().required(),
  type: yup.string().required(),
  categoryOrdering: yup.string().required()
});

const ChecklistAnalisysVisitReportForm = props => {
  const { t } = useTranslation("checklistAnalisys");
  let {
    setFieldValue,
    submitForm,
    errors,
    touched,
    initDataForm,
    handleBack, 
    disabled 
  } = props;
  const { teams, segments, users, currentVisit, visits } = initDataForm;
  
  const [visit, setVisit] = useState(null);
  const [key, setKey] = useState(null);
  const [groups, setGroups] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedOpreation, setSelectedOpreation] = useState({});
  const [, globalActions] = useGlobal();

  const renderOperations = (operations) => {
    let globalOperations = [];
    operations.map(operation => { 
      if(operation.id === 5) {
        globalOperations.push('globalEfficacy');
      }

      if (operation.id === 1) {
        globalOperations.push('global');
      }
     });

    setOperations(globalOperations);

    return globalOperations;
  }


  useEffect(() => {
    //New Report config
    if (visits.length > 0 && visit === null && !currentVisit) {
        setVisit(visits[0]);
        setFieldValue("visit", visits[0]._id);
        setFieldValue("keyField", visits[0].keys[0]);
        setKey(visits[0].keys.length > 0 ? visits[0].keys[0] : null);
  
        const allOperations = visits[0].checkListConfiguration &&
        visits[0].checkListConfiguration.columns 
        ? getOperationsFromColumns(visits[0].checkListConfiguration.columns) : [];
  
        const globalOperations = renderOperations(allOperations);
  
        setSelectedOpreation(globalOperations.length ? globalOperations[0]: null);
        setFieldValue('operation', globalOperations.length ? globalOperations[0]: null);

    }

    //For Edit operation
    if (visits.length > 0 && visit === null && currentVisit) {
      let visitfind = visits.find(visit => {
        return visit._id.toString() === currentVisit.visit.toString();
      });

      if (visitfind) {
        setVisit(visitfind);
      }
      
      setKey(currentVisit.keyField ? currentVisit.keyField : null);

      const allOperations =
        visitfind &&
        visitfind.checkListConfiguration &&
        visitfind.checkListConfiguration.columns
          ? getOperationsFromColumns(visitfind.checkListConfiguration.columns)
          : [];

      const globalOperations = renderOperations(allOperations);
      setSelectedOpreation(globalOperations.length ? globalOperations[0]: null);
      setFieldValue('operation', globalOperations.length ? globalOperations[0]: null)
    }

    let tmpGroups = [];
    if (teams.length > 0) {
      teams.map(team => { tmpGroups.push({ ...team, type: 'team' }) });
    }

    if (segments.length > 0) {
      segments.map(segment => { tmpGroups.push({ ...segment, type: 'segment' }) });
    }
    
    
    setGroups(tmpGroups);
  }, [props.visits]);

  return (
    <Form>
      <GridContainer justify="center">
        <GridItem xs={8}>
          <Field
            name="name"
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  id="name"
                  required
                  error={errors.name && touched.name}
                  helperText={
                    errors.name &&
                    touched.name &&
                    t("checklistAnalisys.form.fields.name.error.isRequired")
                  }
                  label={t("checklistAnalisys.form.fields.name.helperText")}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                />
              );
            }}
          />
        </GridItem>
        <GridItem xs={8}>
          <Autocomplete
            disabled={disabled}
            id="visit"
            value={visit}
            options={visits}
            getOptionLabel={option => option.name}
            onChange={(event, newValue) => {
              setVisit(newValue);
              setFieldValue("visit", newValue !== null ? newValue._id : null);
              setKey(
                newValue !== null && newValue.keys.length > 0
                  ? newValue.keys[0]
                  : null
              );
              setFieldValue(
                "keyField",
                newValue !== null && newValue.keys.length > 0
                  ? newValue.keys[0]
                  : null
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={t("checklistAnalisys.form.fields.visit.helperText")}
                margin="normal"
                fullWidth
                error={errors.visit && touched.visit}
                helperText={
                  errors.visit &&
                  touched.visit &&
                  t("checklistAnalisys.form.fields.visit.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={8}>
          <Autocomplete
            disabled={disabled}
            id="keyField"
            value={key}
            options={visit !== null ? visit.keys : []}
            onChange={(event, newValue) => {
              setKey(newValue);
              setFieldValue("keyField", newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={t("checklistAnalisys.form.fields.keyField.helperText")}
                margin="normal"
                fullWidth
                error={errors.keyField && touched.keyField}
                helperText={
                  errors.keyField &&
                  touched.keyField &&
                  t("checklistAnalisys.form.fields.keyField.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={8}>
          <Autocomplete
            disabled={disabled}
            multiple
            filterSelectedOptions
            id="groups"
            options={groups}
            getOptionLabel={option => `${option.name} (${t(`checklistAnalisys.type.${option.type}`)})`}
            defaultValue={currentVisit ? currentVisit.groups : []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={`${option.name} (${t(`checklistAnalisys.type.${option.type}`)})`} {...getTagProps({ index })} />;
              })
            }
            onChange={(event, value) => {
              setFieldValue('groups', value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t("checklistAnalisys.form.fields.groups.helperText")}
                fullWidth
                margin="normal"
              />
            )}
          />
        </GridItem>
        <GridItem xs={8}>
          <Autocomplete
            disabled={disabled}
            multiple
            freeSolo
            filterSelectedOptions
            id="emails"
            error={errors.emails && touched.emails}
            errorText={touched.emails && errors.emails}
            options={users.map(user => user.email)}
            defaultValue={currentVisit ? currentVisit.emails : []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return <Chip label={option} {...getTagProps({ index })} />;
              })
            }
            onChange={(event, value) => setFieldValue("emails", value)}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label={t("checklistAnalisys.form.fields.emails.helperText")}
                fullWidth
                margin="normal"
                error={errors.emails && touched.emails}
                helperText={
                  errors.emails &&
                  touched.emails &&
                  t("checklistAnalisys.form.fields.emails.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={8}>
          <Autocomplete
            disabled={disabled}
            id="operation"
            value={selectedOpreation}
            options={operations}
            getOptionLabel={option => t(`checklistAnalisys.operations.${option}`)}
            onChange={(event, newValue) => {
              setSelectedOpreation(newValue);
              setFieldValue("operation", newValue);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={t("checklistAnalisys.form.fields.operation.helperText")}
                margin="normal"
                fullWidth
                error={errors.operation && touched.operation}
                helperText={
                  errors.keyField &&
                  touched.keyField &&
                  t("checklistAnalisys.form.fields.operation.error.isRequired")
                }
              />
            )}
          />
        </GridItem>
        <GridItem xs={8}>
          <FormLabel id="categoryOrderRadioGroup">{t("checklistAnalisys.form.fields.order.label")}</FormLabel>
          <Field name="categoryOrdering">
            {({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby="categoryOrderRadioGroup"
                value={field.value || "asc"}
              >
                <FormControlLabel value="asc" control={<Radio />} label={t("checklistAnalisys.form.fields.order.options.asc")} />
                <FormControlLabel value="desc" control={<Radio />} label={t("checklistAnalisys.form.fields.order.options.desc")} />
              </RadioGroup>
            )}
          </Field>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem>
          <ButtonIcon
            onClick={handleBack}
            icon={Cancel}
            color={"primary"}
            label={t("checklistAnalisys.form.buttons.previous")}
          />
          {/* <NavLink to={"/checklistAnalisysVisit"}>
            <ButtonIcon
              icon={Cancel}
              color={"primary"}
              label={t("checklistAnalisys.form.buttons.cancel")}
            />
          </NavLink> */}
        </GridItem>
        <GridItem>
          <ButtonIcon
            disabled={disabled}
            icon={CheckCircleIcon}
            label={t("checklistAnalisys.form.buttons.accept")}
            color={"info"}
            block={true}
            onClick={() => {
              submitForm();
            }}
          />
        </GridItem>
      </GridContainer>
    </Form>
  );
};

const ChecklistAnalisysVisitReportFormWF = withFormik({
  validationSchema: checklistAnalisysFormSchema,
  enableReinitialize: true,
  mapPropsToValues: ({ initDataForm }) => {
    const { app, visits, currentVisit } = initDataForm;
    let visit = "";
    let key = "";
    let name = "";
    let groups = [];
    let emails = [];
    let operation = "";
    let categoryOrdering = "";

    /* if (visits.length > 0 && !currentVisit) {
      visit = visits[0]._id;
      key = visits[0].keys[0];


    } */
    if (visits.length > 0 && currentVisit) {
      visit = currentVisit.visit;
      key = currentVisit.keyField;
      name = currentVisit.name;
      groups = currentVisit.groups;
      emails = currentVisit.emails;
      operation = currentVisit.operation;
      categoryOrdering = currentVisit.categoryOrdering;
    }

    return {
      app: app,
      visit: visit,
      keyField: key,
      name: name,
      groups: groups,
      emails: emails,
      operation: operation,
      type: 'ExitVisit',
      categoryOrdering: categoryOrdering
    };
  },
  handleSubmit: (values, { props }) => {
    props.submitSuccess(values);
  }
})(ChecklistAnalisysVisitReportForm);
export default ChecklistAnalisysVisitReportFormWF;
